<template>
	<main></main>
</template>

<script>
export default {
	name: "longList"
}
</script>

<style scoped>

</style>